.filepicker-container {


  > .filepicker-values {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .filepicker-value {
      display: flex;
      align-items: start;
      gap: .5rem;
      justify-content: flex-start;

      margin-bottom: .5rem;

      > input {
        display: none;
      }

      > .filepicker-value-preview {
        img {
          display: block;
          max-width:400px;
          max-height: 400px;
          width: auto;
          height: auto;
        }
      }

      > .filepicker-delete {
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        color: var(--mc-danger-button-color);

        cursor: pointer;

        &:hover {
            color: var(--mc-danger-button-color-hover);
        }
      }
    }

    > .filepicker-template {
      display: none;
    }
  }

  > .filepicker-upload {
    border: var(--mc-form-input-border);
    background: var(--mc-form-input-bkg);
    border-style: dashed;

    display: flex;

    padding: .5rem 1rem;
    border-radius: .375rem;

    flex-direction: column;
    gap: .375rem;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &.hidden {
      display: none;
    }

    input[type="file"] {
      display: none;
    }

    > .filepicker-icon > svg {
      width: 1.5rem;
      height: 1.5rem;
      stroke: var(--mc-form-input-disabled-color);
    }

    > .filepicker-limitations {
      font-size: smaller;
      color: var(--mc-form-input-disabled-color);
    }
  }
}