span.invoice-late-box {
  display: inline-block;
  min-width: 1.8em;
  margin: 0px 2px;
  border: 1px solid black;
  text-align: center;
  color: black;

  &.invoice-late-2R,
  &.invoice-late-3R,
  &.invoice-late-sue {
    font-family: 'Noto Sans Bold', sans-serif;
  }
}

.invoice-status {
  padding: 3px 5px;
  display: inline-block;
  color: black;

  &.invoice-status-paid {
    color: #3c763d;
    background-color: #dff0d8;
  }

  &.invoice-status-sent {
    color: #8a6d3b;
    background-color: #fcf8e3;
  }

  &.invoice-status-waiting {
    color: #31708f;
    background-color: #d9edf7;
  }

  &.invoice-status-abandoned{
    color: red;
    background-color: #ccc;
  }

  &.invoice-status-idle {
    color: #8F3187;
    background-color: #F7D9F7;
  }
}


.lawsuit-status {
  padding: 3px 5px;
  display: inline-block;
  color: black;

  &.lawsuit-status-paid,
  &.lawsuit-status-defaulted {
    background-color: #a3cfbb;
  }

  &.lawsuit-status-refused,
  &.lawsuit-status-dismissed {
    background-color: #f1aeb5;
  }

  &.lawsuit-status-task {
    background-color: #9ec5fe;
  }

  &.lawsuit-status-waiting {
    background-color: #ffe69c;
  }
}

.lawsuit-late {
  padding: 3px 5px;
  display: inline-block;
  color: black;

  &.late {
    background-color: #f1aeb5;
  }

  &.reminder {
    background-color: #fecba1;
  }
}
