.duration-container {
  position: relative;

    .duration-menu {
        position: absolute;
        top: calc(100%);
        left: 0;
        display: none;
        z-index: 99;

        box-shadow: var(--mc-menu-box-shadow);
        background: var(--mc-menu-bkg);
        border: var(--mc-menu-border);
        border-radius: var(--mc-menu-border-radius);
        color: var(--mc-menu-text-color);

        &.open {
            display: block;
        }

        .inner-menu {
            width: 250px;
            height: auto;
            padding: 0px 15px 15px 15px;
        }

        .duration-menu-preview {
            display: flex;
            padding: 20px 0px;

            align-items: baseline;
            justify-content: center;

            .duration-label {
                color: var(--mc-menu-text-light-color);
            }

            .duration-value {
                font-size: larger;
                padding: 0px 5px 0px 15px;
            }
        }

        .duration-menu-buttons {
            display: flex;
            flex-wrap: wrap;

            gap: 5px;

            button {
                border: 0px;
                background: none;

                width: calc(33% - 5px);
                height: 40px;

                &:hover {
                    background: var(--mc-menu-button-bkg-hover);
                }
            }
        }

        &.disabled,
        &.readonly {
            .duration-menu-buttons {
                display: none;
            }

            .inner-menu {
                padding: 0px 15px;
            }
        }
    }

    .duration-preview:read-only,
    .duration-preview:disabled {
        color: var(--mc-menu-text-lighter-color);
    }
}