
.tooltip-element {
    display: none;
    width: max-content;
    position: absolute;
    background: var(--mc-tooltip-content-bkg);
    color: var(--mc-tooltip-content-color);
    top: 0;
    left: 0;
    padding: 5px;
    border-radius: 4px;

    .table {
      color: var(--mc-tooltip-content-color);

        tbody:first-child td {
            border-top: none;
        }

        margin-bottom: 0;
    }
  }
  
  .tooltip-element-arrow {
    position: absolute;
    background: var(--mc-tooltip-content-bkg);
    color: var(--mc-tooltip-content-color);
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
  }