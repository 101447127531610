

.modal-container {
    overflow-x: hidden;
    overflow-y: auto;

    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: calc(100% - 1rem);

    display: none;

    z-index: 50;

    position: fixed;
    top: 0;
    right: 0;

    &.modal-open {
        display: flex;
    }
}

.modal-backdrop {
    background: var(--mc-modal-backdrop-bkg);
    z-index: 40;
    inset: 0;
    position: fixed;
    display: none;

    &.modal-open {
        display: block;
    }
}

.modal-content {
    padding: 1rem;
    max-width: 42rem;
    width: 100%;
    max-height: 100%;
    position: relative;


    .modal-content-inner {
        border-radius: .5rem;

        background: var(--mc-modal-content-bkg);
        color: var(--mc-modal-content-text-color);
    }
}

@media (min-width: 768px) {
    .modal-container {
        inset: 0;
    }
}