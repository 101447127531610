.project-favorite {

  cursor: pointer;

  &.favorite-on {
    color: #336699;
  }
  &.favorite-off {
    color: #999;
  }
}

tr.project {
  .bad {
    color: red;
  }

  &.project-late,
  &.project-overrun {
    background: rgb(248, 215, 218);

  }
}