
.form-buttons {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.form-buttons > button {
  flex-basis: max-content;
}

.form-fields + .form-fields,
.form-field + .form-field {
  margin-top: 1.5rem;
}

.form-fields + .form-button,
.form-field + .form-button {
  margin-top: 1.5rem;
}

.form-feedback {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: .375rem;
    display: none;

    &.form-feedback-description {
        color: var(--mc-form-color-feedback-description);
        display: block;
    }

    &.form-feedback-valid {
        color: var(--mc-form-color-feedback-valid);
    }

    &.form-feedback-invalid,
    &.form-feedback-required {
        color: var(--mc-form-color-feedback-invalid);
    }
}

.form-field.form-field-required-empty {
    > .form-feedback-required {
        display: block;
    }

    .form-input {
        border: var(--mc-form-input-invalid-border);
    }
    .form-label {
        color: var(--mc-form-input-invalid-color);
    }
}

.form-field.form-field-invalid {
    > .form-feedback-invalid {
        display: block;
    }

    .form-input {
        border: var(--mc-form-input-invalid-border);
    }
    .form-label {
        color: var(--mc-form-input-invalid-color);
    }
}

.form-label {
  color: white;
  line-height: 1.5rem;
  font-weight: 600;
  font-size: .875rem;
  padding: 0 0 .375rem 0;
  display: block;

  .form-label-required {
    color: var(--mc-form-color-label-required);
    padding-left: 5px;
  }
}

.form-input,
.form-button {
  display: block;
  color: var(--mc-form-input-color);
  padding: .5rem 1rem;
  border-radius: .375rem;
}

.form-input {
  width: 100%;
  border: var(--mc-form-input-border);
  background: var(--mc-form-input-bkg);

  &:disabled {
    color: var(--mc-form-input-disabled-color);
    background: var(--mc-form-input-disabled-bkg);
    border: var(--mc-form-input-disabled-border);
    cursor: not-allowed;
  }
  
  ::placeholder {
      color: var(--mc-form-input-placeholder-border);
  }
}


button.form-button,
.form-button {
  border: 0px;
  background-color: var(--mc-form-button-bkg);

  display: inline-flex; 
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--mc-form-button-hover-bkg);
  }
  
  &:disabled {
    color: var(--mc-form-button-disabled-color);
    background-color: var(--mc-form-button-disabled-bkg);
    border-color: var(--mc-form-button-disabled-border);

    cursor: not-allowed;

    &:hover {
      background-color: var(--mc-form-button-disabled-bkg);
    }
  }
}

.button.button-primary,
.form-button.button-primary {
  background-color: var(--mc-form-button-primary-bkg, #1d4ed8);
  border-color: var(--mc-form-button-primary-border, #334155);
  color: var(--mc-form-button-primary-color, white);

  &:hover {
    background-color: var(--mc-form-button-primary-hover-bkg, #3b82f6);
    border-color: var(--mc-form-button-primary-hover-border, #334155);
    color: var(--mc-form-button-primary-hover-color, white);
  }
}

.button.button-secondary,
.form-button.button-secondary {
  background-color: var(--mc-form-button-secondary-bkg, #374151);
  border-color: var(--mc-form-button-primary-border, #334155);
  color: var(--mc-form-button-primary-color, white);

  &:hover {
    background-color: var(--mc-form-button-secondary-bkg, #6b7280);
    border-color: var(--mc-form-button-primary-border, #334155);
    color: var(--mc-form-button-primary-color, white);
  }
}

.form-button.button-info {
  background-color: rgb(204, 251, 241);
  border-color: rgb(20, 184, 166);
  color: rgb(19, 78, 74);

  &:hover {
    background-color: rgb(20, 184, 166);
  }
}

.form-button.button-success {
  background-color: rgb(220, 252, 231);
  border-color: rgb(34, 197, 94);
  color:  rgb(20, 83, 45);

  &:hover {
    background-color: rgb(34, 197, 94);
  }
}

.form-button.button-warning {
  background-color: #c2410c;

  &:hover {
    background-color: #f97316;
  }
}

.form-button.button-error {
  background-color:rgb(254, 226, 226);
  border-color: rgb(239, 68, 68);
  color: rgb(127, 29, 29);

  &:hover {
    background-color: rgb(239, 68, 68);
  }
}

.form-field > select {
  height: 42px;
}

.form-field {
    .form-input-checkbox {
        margin-left: .375rem;
        display: flex;
        align-items: center;
        gap: .375rem;
        justify-content: flex-start;

        > input {
            height: 1rem;
            width: 1rem;
        }

        .form-input-checkbox-description {
          color: var(--mc-form-color-feedback-description);
        }
    }
}

input[type="checkbox"] {
  min-width:  14px;
  min-height: 14px;
}

input[type="file"].form-input {
  cursor: pointer;
}


.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--form-input-color, white);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 1120px 1120px var(--mc-form-button-bkg);
}

.form-toggle {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
}

.form-toggle > .toggle {
  width: 2.75rem;
  height: 1.5rem;
  border-radius: 9999px;
  background-color: rgb(55 65 81);
  border-color: rgb(75 85 99);
}

.form-toggle > .toggle::after {
  content: "";
  position: absolute;
  background-color: white;
  border: 1px solid rgb(209 213 21);
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
  top: 2px;
  left: 2px;

  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.form-toggle > input:checked ~ .toggle {
  background-color: rgb(28 100 242);
  border-color: rgb(75 85 99);
}

.form-toggle > input:checked ~ .toggle::after {
  transform: translate(100%,0);
}

.form-toggle > .toggle-label {
  margin-left: .75rem;
}
