.autocomplete {
    .autocomplete-input-menu {
        position: relative;
    }

    .autocomplete-menu {
        display: none;
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        z-index: 99;
        width: 100%;
        min-width: min-content;

        border-top: 0px;
        list-style: none;
        padding-left: 0;

        box-shadow: var(--mc-menu-box-shadow);
        background: var(--mc-menu-bkg);
        border: var(--mc-menu-border);
        border-radius: var(--mc-menu-border-radius);
        color: var(--mc-menu-text-color);

        &.open {
            display: block;
        }

        > li {
            cursor: pointer;
            padding: .5rem;

            &.hover,
            &:hover {
                background: var(--mc-menu-bkg-hover);
            }

            &.autocomplete-menu-item-noresult.hover,
            &.autocomplete-menu-item-noresult:hover {
                background: var(--mc-menu-bkg);
            }
        }
    }

    .autocomplete-menu-noresult {
        display: none;
    }

    .autocomplete-multi-template {
        display: none;
    }

    .autocomplete-multi-values {
        padding: 0 .5rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }


    .autocomplete-multi-value {
        margin-top: .375rem;
        padding-top: .375rem;
        display: flex;
        justify-content: space-between;

        > a.autocomplete-value-delete {
            color: var(--mc-danger-button-color);
            cursor: pointer;

            &:hover {
                color: var(--mc-danger-button-color-hover);
            }

            svg {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}
