.datetimepicker-container,
.datepicker-container {
  position: relative;

    & > .datepicker-icon {
        position: absolute;
        top: .375rem;
        right: .75rem;
        color: #666;
        pointer-events: none;
    }

    .datepicker-menu {
        box-shadow: var(--mc-menu-box-shadow);
        position: absolute;
        top: calc(100%);
        left: 0;
        display: none;
        z-index: 99;
        background: var(--mc-menu-bkg);
        border: var(--mc-menu-border);
        border-radius: var(--mc-menu-border-radius);
        color: var(--mc-menu-text-color);

        &.open {
            display: block;
        }
    }

    .inner-menu {
        width: 250px;
        height: auto;
        padding: 0px 15px 15px 15px;
        user-select: none;

        .month-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px;
        }

        .month-selector-buttons {
            display: flex;
            gap: 5px;
        }
    }

    .week {
        display: flex;
    }

    .month-selector-buttons > button {
        border: 0px;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        background: var(--mc-menu-button-bkg);

        display: flex;
        align-items: center;
        justify-content: space-around;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .month-selector-buttons > button:hover {
        background: var(--mc-menu-button-bkg-hover);
    }

    .day {
        border: 0px;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        background: none;
    }

    .day:hover {
        background: var(--mc-menu-button-bkg-hover);
    }

    .week-header {
        text-decoration: underline;
    }

    .week > * {
        width: calc(100% / 7);
        text-align: center;
    }

    .week .day-weekend {
        color: var(--mc-menu-text-light-color);
    }

    .week .day-around {
        color: var(--mc-menu-text-lighter-color);
    }
}