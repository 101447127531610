
.dropdown {
	display: inline-block;
	position: relative;
	.dropdown-button {
		display: inline-flex;
		width: 100%;
		justify-content: center;
	}
	.dropdown-menu {
		position: absolute;
		top: calc(100%);
		left: 0;
		width: min-content;
		z-index: 99;
		padding: 0px;
		display: none;
 
        box-shadow: var(--mc-menu-box-shadow);
        background: var(--mc-menu-bkg);
        border: var(--mc-menu-border);
        border-radius: var(--mc-menu-border-radius);
        

        &.show,
        &.open {
            display: unset;
        }

        >li {
            >a {
                display: block;
                padding: 5px 10px;
                color: var(--mc-menu-text-color);
                &:hover {
                    text-decoration: none;
                    background: var(--mc-menu-bkg-hover);               
                }
            }

            &.separator {
                background: var(--mc-menu-separator-bkg);
                height: 4px;
            }
        }
	}

    &.dropdown-right {
        .dropdown-menu {
            right: 0;
            left: unset;
            transform-origin: top right;
        }
    }
}
