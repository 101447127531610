
.multiselect {
    position: relative;

    > .multiselect-preview {
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--mc-form-input-color);
        background-color: var(--mc-form-input-bkg);
        background-clip: padding-box;
        border: var(--mc-form-input-border);
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
        &.multiselect-preview-empty {
            color: var(--mc-form-input-placeholder-color);
        }
    }

    > ul {
        list-style: none;
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        display: none;
        z-index: 99;
        width: 100%;
        border-radius: var(--mc-menu-border-radius);
        border: var(--mc-menu-border);
        box-shadow: var(--mc-menu-box-shadow);
        border-top: 0px;
        background-color: var(--mc-menu-bkg);
        list-style: none;
        padding-left: 0px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        max-height: 400px;
        overflow-y: scroll;

        &.open {
            display: block;
        }

        > li {
            cursor: pointer;

            > label {
                display: block;
                width: 100%;
                padding: 6px 10px;
                margin-bottom: 0px;
            }

            &.hide {
                display: none;
            }

            &.multiselect-search,
            &.multiselect-checkall {
                background: var(--mc-menu-section-light-bkg);
                border-top: var(--mc-menu-section-light-border);
                border-bottom: var(--mc-menu-section-light-border);
            }


            &.multiselect-search {
                > label {
                    display: flex;

                    .multiselect-item-icon {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                    }

                    input.multiselect-item-search {
                        width: 100%;
                        background: transparent;
                        border: none;
                    }

                    input.multiselect-item-search:focus,
                    input.multiselect-item-search:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        li.multiselect-search ~ li.multiselect-checkall {
            border-top: none;
        }

    }

    .multiselect-checkbox {
        margin-right: 5px;
    }
}


