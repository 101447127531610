@use '../../../../mig_core/components/variables.scss';
@use '../../../../mig_core/components/autocomplete.scss';
@use '../../../../mig_core/components/dropdown.scss';
@use '../../../../mig_core/components/datepicker.scss';
@use '../../../../mig_core/components/multiselect.scss';
@use '../../../../mig_core/components/duration.scss';
@use '../../../../mig_core/components/modal.scss';
@use '../../../../mig_core/components/listing.scss';
@use '../../../../mig_core/components/forms.scss';
@use '../../../../mig_core/components/tooltip.scss';
@use '../../../../mig_core/components/filepicker.scss';

@use  '../projects/projects_common.scss';
@use  '../crm/crm_common.scss';
@use  '../todo/todo_common.scss';


@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans Italic';
  src: url('fonts/NotoSans-Italic.ttf');
}

@font-face {
  font-family: 'Noto Sans Bold';
  src: url('fonts/NotoSans-Bold.ttf');
}


:root {
  --fc-button-bg-color: #ededed;
  --fc-button-border-color: #ddd;
  --fc-button-text-color: #999;
  --fc-button-hover-bg-color: #ccc;
  --fc-button-hover-border-color: #999;
  --fc-button-active-bg-color: #ccc;
  --fc-button-active-border-color: #999;

  --mc-form-input-bkg: #ffffff;
  --mc-form-input-border: 1px solid #ced4da;
  --mc-form-input-color: black;

  --mc-form-input-disabled-color: #999;
  --mc-form-input-disabled-bkg: #efefef;
  --mc-form-input-disabled-border: 1px solid #ced4da;

  --mc-danger-button-color: red;

  --mc-tooltip-content-bkg: #ededed;
  --mc-tooltip-content-color: #333;

  --mc-modal-backdrop-bkg: #000;
}

.form-field > select {
  height: 2.5rem;
}

.form-label {
  color: black;
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0;
}

strong {
  font-family: 'Noto Sans Bold', sans-serif;
  font-weight: normal;
  font-style: normal;
}

em {
  font-family: 'Noto Sans Italic', sans-serif;
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Noto Sans", sans-serif;
}

#topbar {
  border-radius: 0px;
  padding: 0px 5px 0px 0px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  color: #999;
}

#topbar.topbar-dev {
  border-bottom: 1px solid #ff0000;
}

#topbar .navbar-nav .nav-item:first-child {
  border: 0px;
  padding-left: 2px;
}

#topbar .navbar-nav .nav-item {
  margin-left: 0.4rem;
  padding-left: 0.4rem;
  border-left: 1px solid #ccc;
}

#topbar .navbar-nav .nav-link {
  padding: 0px;
}

.login-bkg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #999;
  padding: 20px;
}

.login-box {
  max-width: 400px;
  margin: 100px auto 0px auto;
  border: 1px solid #333;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 2px #888888;
}

.login-webauthn-prompt {
  text-align: center;
  width: 200px;
  border: 1px dashed #999;
  border-radius: 3px;
  margin: 20px auto;
  padding: 10px;
  color: #666;
}

.login-webauthn-prompt > i {
  font-size: 4em;
  display: block;
}

#login {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #999;
  padding: 20px;
}

#login .card {
  max-width: 400px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
}

.card.error {
  text-align: left;
}

html {
  font-size: 14px;
  height: 100%;
}
body {
  min-height: 100%;
}

h1.title {
  font-size: 2em;
  color: #B50101;
  margin: 0.7rem 0rem 0.5rem 0.7rem;
  background:  url("images/icon.svg") no-repeat top left;
  background-size: contain;
  padding-left: 40px;
  float:left;
}

nav#mainMenu {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.2rem;
}

nav#mainMenu li.nav-item {
  margin: 4px 3px 0px 3px;
  padding: 2px;
  border-top: 1px #336699 solid;
  border-left: 1px #336699 solid;
  border-right: 1px #336699 solid;
  background:#F0F0F0;
  white-space: nowrap;
}

nav#mainMenu a.nav-link {
  color: #333;
  padding: 0px 4px;
  text-decoration:none;
}


nav#mainMenu li:hover, nav#mainMenu li.active {
  background-color: #336699;
}

nav#mainMenu li:hover a.nav-link, nav#mainMenu li.active a.nav-link {
  color: #fff;
}

nav#subMenu {
  background:#336699;
  min-height: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  padding: 0px;
}

nav#subMenu li.nav-item {
  margin: 0px 3px 0px 3px;
  padding: 2px;
}

nav#subMenu a.nav-link {
  color: #99CCFF;
  padding: 2px 4px;
}

nav#subMenu li:hover a.nav-link, nav#subMenu li.nav-item.active a.nav-link {
  color: #fff;
  background-color: #336699;
  text-decoration:none;
}

div.simple-content {
  padding: 0px 10px;
  margin-bottom: 20px;

  &.simple-content-sm {
    max-width: 800px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .switch {
      display: flex;
      align-items: center;
      gap: 5px;

      > i {
        color: #999;
      }
    }
  }
}


.breadcrumb {
  padding: .4rem 1rem;
}

div.breadcrumb-empty {
  margin-bottom: 1rem;
}

div.popover-hidden-content {
  display: none;
}

h2 {
  font-size: 1.5rem;
  margin: 0.8rem 0;
}

h3 {
  font-size: 1.3rem;
  margin: 2rem 0 0.8rem 0;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0.8rem 0;
}

#nav-notifications {

  a.list-group-item {
    color: #333;
  }

  &.dropdown a.dropdown-button {
    color: #ccc;
    display: unset;
  }

  &.has-notifications a.dropdown-button {
    color: #B50101;
  }

  &.dropdown .dropdown-menu {
    min-width: 500px;
  }
}

.popover.bs-tether-element-attached-top.popover-notifications {
  border-radius: 0px 5px 5px 5px;
}
.popover.bs-tether-element-attached-top.popover-notifications .popover-arrow {
  left: 10px;
}
.popover.popover-notifications {
  max-width: 500px;
}
.popover.popover-notifications .list-group {
  padding: 0px;
}
.popover.popover-notifications .list-group-item {
  border-left: 0px;
  border-right: 0px;
}

tr.collapse.in {
  display: table-row;
}

div.search-pane {
  margin-top: -17px;
  border-right: 1px solid #336699;
  background: rgba(232, 243, 253, 0.14);
  padding: 10px;
  font-size: 14px;
}

div.notifications {
  padding: 0px 10px;
}

div.center-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

}

div.popover-notifications {
  min-width: 300px;
  max-width: 100%;
}
div.popover-notifications .popover-body {
  padding: 0px;
  margin-bottom: 0px;
}

div.popover-notifications .popover-body a {
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  color: #333;
}

div.popover-notifications .popover-body .list-group-item {
  border: 0px;
}

@media (max-width: 992px) {
  nav.navbar.navbar-toggleable-sm .navbar-nav .nav-item {
    border: 0px;
  }
  nav.navbar.navbar-toggleable-sm {
    min-height: 30px;
  }

  nav#mainMenu li.nav-item { 
    margin: 0px;
    border: 0px;
    padding: 5px;
    border-left: 1px solid #336699;
    background: none;
  }

  nav#mainMenu li:hover a.nav-link, nav#mainMenu li.active a.nav-link {
    font-weight: bold;
    color: #333;
  }
}

#topbar > button.navbar-toggler {
  margin: 2px;
}

#mainMenu > button.navbar-toggler {
  margin: 0px;
  padding: 5px;
  border-top: 1px #336699 solid;
  border-left: 1px #336699 solid;
  border-right: 1px #336699 solid;
  border-radius: 0px;
}

#subMenu > button.navbar-toggler {
  margin: 0px;
  padding: 5px;
  color: white;
}

textarea {
  resize: both !important;
}

table tr th.min-width,
table tr td.min-width {
  width: 1%;
  white-space: nowrap;
  padding-right: 10px;
}

table td.nowrap, table th.nowrap {
  white-space: nowrap;
}

.dropdown-item.active {
  background-color: #abd4ff;
}

.js_loader {
  display: none;
}

.onloading {
  position: relative;
}

.onloading > .js_loader {
    display: flex;
    cursor: wait;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 0.5);
    z-index: 90;
}

.onloading > .js_loader .js_loader_icon {
    margin:auto;
    max-width: 80%; 
    max-height: 80%; 
}

.js_loader i {
  color: #ccc;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

table .col-sep {
  border-left: 1px solid #ddd;
  margin-left: 4px;
}

table .col-double-sep {
  border-left: 3px solid #ddd;
  margin-left: 8px;
  padding-left: 8px;
}

.html-diff pre ins {
  color: green;
  text-decoration: none;
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.html-diff pre del {
  color: red;
  text-decoration: none;
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

table.table-dynamic,
.table-dynamic table {
  width: auto;
  min-width: 600px;
}

.control-label.required::after {
  color: red;
  content: " *";
}


.user_badge {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 14px;
  line-height: 26px;

  border: 2px solid #ebebeb;
  background-color: #999;
  text-align: center;
  font-weight: bold;
  color: #ebebeb;
}

.user_badge.user_badge_3 {
  font-size: smaller;
  font-weight: bold;

}

.user_badge:hover,
.user_badge:active {
  text-decoration: none;
}

.pane-content {
  width: 100%;
  max-width: 1000px;
}

.form-disabled {
  opacity: .5;
  pointer-events: none;
}

.input-group-text span.popover-auto a {
  color: #999;
}

div.panes-sidebar {
  margin-top: -14px;
  display: grid;
  grid-template-columns: minmax(300px, 20%) 1fr;
}

div.panes-sidebar > .sidebar {
  border-right: 1px solid #336699;
  background: rgba(232, 243, 253, 0.14);
}

div.panes-sidebar > * {
  padding: 10px;
}

.btn-light-danger {
  color: #212529;
  background-color: #ffe9e6;
  border-color: #ffe9e6;
}

.btn-light-danger:not(:disabled):not(.disabled):active,
.btn-light-danger:hover {
  color: white;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light-warning {
  color: #212529;
  background-color: #fff9e6;
  border-color: #fff9e6;
}

.btn-light-warning:not(:disabled):not(.disabled):active,
.btn-light-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.totp_code {
  font-size: 3em;
  width: 8ch;
  text-align: center;
}

.totp_box {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}


.git-changeid,
.git-commit,
.git-pr-status,
.git-branch {
  text-transform: uppercase;
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
  font-size: smaller;
  padding: 1px 3px;
  border: 1px solid;
  border-radius: 3px;
}

.git-pr-rejected {
  background: #d73a49;
  border-color: #d73a49;
  color: white;
}

.git-pr-accepted {
  background: #28a745;
  border-color: #28a745;
  color: white;
}

.git-pr-merged {
  background: #6f42c1;
  border-color: #6f42c1;
  color: white;
}

.git-pr-abandoned {
  background: #ededed;
  border-color: #ededed;
  color: red;
}

.git-commit {
  color: #856404;
  background: #fff3cd;
  border-color: #f2d98a;
}

.git-commit:hover {
  text-decoration: none;
  color: #856404;
  background: #f2d98a;
}

.git-changeid {
  color: #336699;
  background: #cce5ff;
  border-color: #b8daff;
}

.git-changeid:hover {
  text-decoration: none;
  color: #336699;
  background: #b8daff;
}

.git-branch {
  text-transform: lowercase;
  color: #601c72;
  background: #ecd7f8;
  border-color: #e3c6f5;
}

.git-branch:hover {
  color: #601c72;
  text-decoration: none;
  background: #e3c6f5;
}

.git-changes {
  margin: 10px 0px;
  padding-left: 20px;
}

.git-changes li {
  list-style: '-' inside;
}

.flex-auto {
  flex: auto;
}

.mw-600 {
  max-width: 600px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1000 {
  max-width: 1000px;
}

.mw-1200 {
  max-width: 1200px;
}

span.duration {
  display: inline-block;
  min-width: 110px;
}

.copy-clipboard {
  cursor: pointer;
}


.form-buttons {
  margin-top: 15px;
}

.form-input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}





.daterange {
  display: flex;
  align-items: center;

  .datepicker-container {
    width: calc(50% - 20px);
  }

  .daterange-to-label {
    color: #999;
    text-align: center;
    width: 40px;
    padding: 0px 5px;
  }
}

a.invisible-link:hover {
  color: #336699;
}

a.invisible-link {
  color: #333;
}

.table-column-order {
  white-space: nowrap;
}

ul.pagination {
  border-radius: 4px;
  display: inline-flex;
}

ul.pagination > li {
  padding: 4px 10px;
  cursor: pointer;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

ul.pagination > li:hover {
  color: #2a6496;
  background-color: #eee;
  border-color: #ddd;
}

ul.pagination > li.active {
  color: #fff;
  cursor: default;
  background-color: #428bca;
  border-color: #428bca !important;
}

ul.pagination > li:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #ddd;
}

ul.pagination > li:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#global-messages-container {
  position: absolute;
  right: 50px;
  top: 50px;
}

.global-message {
  background: white;
  min-width: 300px;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.175) !important;
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.global-message.hiding {
  opacity: 0;
}

.global-message-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.global-message-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.global-message-error {
  color: #842029;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.global-message-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.global-message-info {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.global-message-text {
  padding: 5px 10px 5px 0px;
}

.global-message-icon {
  padding: 10px 10px 10px 15px;
}

.global-message-success .global-message-icon i:before {
  content: "\f00c";
}




.form-toggle {

  display: block;
  position: relative;

  cursor: pointer;

  .toggle-checkbox {
    display: none;
  }

  margin: 0;

  > .toggle {
    width: 2.75rem;
    height: 1.5rem;
    border-radius: 9999px;
    background-color: #ededed;
    border: 1px solid #999;

    &::after {
      content: "";
      position: absolute;
      background-color: white;
      border: 1px solid #999;
      border-radius: 9999px;
      width: 1.25rem;
      height: 1.25rem;
      top: 2px;
      left: 2px;

      transition-duration: .15s;
      transition-property: all;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
    }
  }

  > input:checked ~ .toggle {
    background-color: rgb(28 100 242);
    border-color: rgb(75 85 99);
  }

  > input:checked ~ .toggle::after {
    transform: translate(100%,0);
  }
}

th.col-sep-l,
td.col-sep-l {
    margin-left: 5px;
    border-left: 3px solid #ddd;
    padding-left: 5px;
}

th.col-sep-r,
td.col-sep-r {
    margin-right: 5px;
    border-right: 3px solid #ddd;
    padding-right: 5px;
}


.progress-bar {
  overflow: visible;
  text-shadow: 0px 0px 2px black;
}


.timeline {
  ul {
    margin-left: 0px;
    padding-left: 15px;
  }

  li {
    list-style: none;
    margin-bottom: 10px;
  }


  .title > .icon {
    padding-right: 5px;
    width: 20px;
    display: inline-block;
  }

  .title > .time {
    color: #999;
    display: inline-block;
    width: 40px;
  }

  .title > a {
    padding-right: 5px;
  }

  .action {
    margin-left: 20px;
    color: #ccc;
  }

  .action-title {
    font-style: italic;
    color: #336699;
  }

  div.comment {
    color: #999;
    margin-left: 65px;
    display: flex;
    align-items: top;
  }

  div.comment .author {
    color: #666;
    white-space: nowrap;
    margin-right: 5px;
  }

  div.comment .changes {
    display: flex;
    flex-wrap: wrap;
  }

  div.comment .changes > span.change {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
  }

  div.comment .changes > span.change  .value-old {
    opacity: .7;
  }
  div.comment .changes > span.change  .value-new {
    padding-right: 5px;
  }

  div.comment .changes > span.change .title {
    padding: 0px 3px;
    background: #ededed;
  }

  div.comment .text {
    max-height: 45px;
    overflow: hidden;
    display: inline-block;
  }
}

td.vertical-col,
th.vertical-col {
  vertical-align: bottom;

  > .vertical-col-wrapper {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;

    > .vertical-col-content {
      writing-mode: vertical-rl;
      transform-origin: 50% 50%;
      transform: rotate(-180deg);
    }
  }
}

table.html-diff {
  width: 100%;
  border-collapse: initial;
  border-spacing: 0;

  td.diff-num {
    width: 1%;
    min-width: 50px;
    font-size: 12px;
    font-family: Consolas,Liberation Mono,Menlo,monospace;
    line-height: 20px;
    color: #ccc;
    user-select: none;
    text-align: center;
    vertical-align: top;
    padding: 0px;

    &:hover {
      color: #999;
    }
  }

  tr.diff-del {
    > td {
      background-color: #ffdce0;
    }
  }

  tr.diff-add {
    > td {
      background-color: #e6ffed;
    }
  }
}

.simple-form {
  max-width: 900px;
}

.title-buttons {
  display: flex;
  align-items: center;
  gap: 10px;

  h1,
  h2,
  h3 {
    display: inline-block;
    margin-right: 10px;
  }
}

fieldset.fields-group {
  border: 1px solid #ececec;
  padding: 15px;
  margin: 0px -10px 15px -10px;

  > legend {
    width: inherit;
    padding: 5px;
    font-size: 1.2em;
    color: #666;
  }
}

.table-pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  margin-bottom: 1rem;
  gap: 1rem;

  > select.table-action-select.form-input {
    width: auto;
  }
}

.table-checkall-banner {
  display: none;

  &.show {
    display: table-row;
  }

  > td {
    text-align: center;
    padding: .5rem;
    background: #f9f6e2;
  }
}

.listing input.form-input.form-input-value[type="checkbox"] {
  height: auto;
  margin: .25rem;

}

/** Compatibility with mig_core modal backdrop */
.modal-backdrop.show {
  display: block;
}
.modal-backdrop.modal-open{
  opacity: .5;
}

.btn-xs {
  padding: .125rem .25rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.form-filters-horizontal {
  background: #eaf0f7;
  border: 1px solid #d2deee;
  border-radius: .375rem;
  padding: .5rem;

  display: flex;
  justify-content: space-between;
  gap: .5rem;

  > .form-field {
    display: flex;
    align-items: center;

    > .form-label {
      margin-right: .375rem;
      padding: 0;
    }
  }

  > .form-field + .form-field {
    margin-top: 0;
  }

  > .form-buttons {
    max-width: max-content;
    margin-top: 0;
  }
}

.hide {
  display: none;
}

fieldset.alert {
  > legend {
    border-top: 1px solid transparent;
    background: transparent;
    border-radius: .25rem;
    width: unset;
    padding-left: .5rem; 
    padding-right: .5rem; 
    font-size: 1.2rem;
  }

  &.alert-warning > legend {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.alert-danger > legend {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}