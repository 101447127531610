.table-todo-items tr.container-small {
  display: none;
}

.table-todo-items td.container-small {
  display: none;
}

.table-todo-items td i.fa {
  color: #999;
}

.table-todo-items i.load-subtickets {
  cursor: pointer;
}

@container (max-width: 600px) {
  .table-todo-items tr.container-small {
    display: table-row;
  }
  .table-todo-items td.container-small {
    display: table-cell;
  }

  .table-todo-items tr.container-small + tr > td {
    border-top: none;
    padding-top: 0;
    font-size: smaller;
  }

  .table-todo-items td.container-big {
    display: none;
  }
}


.table-todo-items tr.status-hidden > td {
    opacity: 0.4;
}

.table-todo-items tr.status-feedback-client > td {
    background-color: #edf7ff;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
}

.table-todo-items tr.waiting-others > td {
    opacity: 0.6;
}

.table-todo-items tr.status-waiting-subtickets > td {
    background-color: #edf7ff;
    opacity: .6;
}

.table-todo-items tr.status-review1 > td {
    background-color: #fff8da;
}
.table-todo-items tr.status-review2 > td {
    background-color: #fff3bb;
}
.table-todo-items tr.status-review3 > td {
    background-color: #ffeea0;
}
.table-todo-items tr.devonly > td {
    background-color: #ffd78b;
}
.table-todo-items tr.status-toupdate1 > td {
    background-color: #e7ffdc;
}
.table-todo-items tr.status-toupdate2 > td {
    background-color: #c6ffad;
}

.table-todo-items tr.flag-unread > td {
  font-family: 'Noto Sans Bold', sans-serif;
  font-weight: normal;
}

.table-todo-items tr.flag-unread > td a {
  font-family: 'Noto Sans Bold', sans-serif;
  font-weight: normal;
}

.table-todo-items tr.flag-snoozed > td {
  opacity: 0.4 !important;
  color: #333 !important;
}

.table-todo-items tr.flag-snoozed > td a {
  color: #333 !important;
}

.table-todo-items .customer-container {
  display: flex;
  gap: .5em;

  align-items: center;

  > img {
    width: 16px;
    height: 11px;
  }
}

.table-todo-items tr:hover > td {
  opacity: 1 !important;
}

.table-todo-items .badge {
  padding: 3px 7px;
  border-radius: 10px;

  color: white;

  &.badge-sla-pro {
    background-color: #99BBFF;
  }

  &.badge-sla-basic {
    background-color: #999999;
  }

  &.badge-sla-vip {
    background-color: #28a745;
  }

  &.badge-sla-premium {
    background-color: #ffc107;
  }
}



.todo-timeline ul {
    margin-left: 0px;
    padding-left: 15px;
}

.todo-timeline li {
  list-style: none;
  margin-bottom: 10px;
}


.todo-timeline .title > .icon {
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.todo-timeline .title > .time {
    color: #999;
    display: inline-block;
    width: 40px;
}

.todo-timeline .title > a {
    padding-right: 5px;
}

.todo-timeline .action {
    margin-left: 20px;
    color: #ccc;
}

.todo-timeline .action-title {
    font-style: italic;
    color: #336699;
}

.todo-timeline div.comment {
    color: #999;
    margin-left: 65px;
    display: flex;
    align-items: top;
}

.todo-timeline div.comment .author {
    color: #666;
    white-space: nowrap;
    margin-right: 5px;
}

.todo-timeline div.comment .changes {
  display: flex;
  flex-wrap: wrap;
}

.todo-timeline div.comment .changes > span.change {
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
}

.todo-timeline div.comment .changes > span.change  .value-old {
  opacity: .7;
}
.todo-timeline div.comment .changes > span.change  .value-new {
  padding-right: 5px;
}

.todo-timeline div.comment .changes > span.change .title {
  padding: 0px 3px;
  background: #ededed;
}

.todo-timeline div.comment .text {
    max-height: 45px;
    overflow: hidden;
    display: inline-block;
}