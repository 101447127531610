ul.pagination {
    border-radius: 4px;
    display: inline-flex;

    > li {
        display: flex;
        align-items: center;

        > svg {
            width: 1rem;
            height: 1rem;
        }

        padding: 4px 10px;
        cursor: pointer;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;

        &:hover {
            color: #2a6496;
            background-color: #eee;
            border-color: #ddd;
        }

        &.active {
            color: #fff;
            cursor: default;
            background-color: #428bca;
            border-color: #428bca !important;
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left: 1px solid #ddd;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.table-column-order {
    display: flex;
    align-items: center;
    gap: .375rem;

    > span {
        white-space: nowrap;
    }

    > svg {
        width: 1rem;
        height: 1rem;
    }
}