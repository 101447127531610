$layout-breakpoint-small: 766px;

:root{
    --mc-menu-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    --mc-menu-border: none;
    --mc-menu-border-radius: 5px;
    --mc-menu-text-color: #333; 
    --mc-menu-bkg: white;
    --mc-menu-bkg-hover: #ededed;
    --mc-menu-button-bkg-hover: #ededed;
    --mc-menu-text-light-color: #999;
    --mc-menu-text-lighter-color: #ccc;

    --mc-menu-section-light-bkg: #efefef;
    --mc-menu-section-light-border: 1px solid #ccc;

    --mc-menu-danger-button-color: #b91c1c;
    --mc-menu-danger-button-color-hover: #dc2626;


    --mc-modal-backdrop-bkg: rgba(17,24,39,.5);
    --mc-modal-content-bkg: white;
    --mc-modal-content-text-color: #333;

    --mc-tooltip-content-bkg: #111;
    --mc-tooltip-content-color: #ccc;

    --mc-form-color-feedback-description: rgb(148, 163, 184);
    --mc-form-color-feedback-valid: rgb(34, 197, 94);
    --mc-form-color-feedback-invalid: red;
    --mc-form-color-feedback-required: red;
    --mc-form-color-label-required: red;

    --mc-form-input-color: white;
    --mc-form-input-placeholder-color: #999;
    --mc-form-input-border: 1px solid #334155;
    --mc-form-input-bkg: #1e293b;

    --mc-form-input-invalid-color: red;
    --mc-form-input-invalid-border: 1px solid red;

    --mc-form-input-disabled-color: #3f3f46; 
    --mc-form-input-disabled-bkg: #27272a;
    --mc-form-input-disabled-border: #3f3f46;

    --mc-form-button-bkg: #1d4ed8;
    --mc-form-button-hover-bkg: #3b82f6;
    --mc-form-button-disabled-color: #3f3f46; 
    --mc-form-button-disabled-bkg: #27272a;
    --mc-form-button-disabled-border: #3f3f46;
}